.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-hero {
  background-image: url('../public/1920x1080-header.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
}

.logo {max-width: 185px; height: auto;}
.logo-small {max-width: 150px; height: auto;}
.main {background-color: #000;}
.iconGroupItem .icon {background-color: transparent;}
h1, h2 {color: #00FFFF;}
h3, h4, h5, h6, p, .iconGroupItem p {color: #FFF;}
footer {border-top: 1px solid #CFD1D5;background: #000000 !important;}
footer:first-child {border-top: none;}
.primary-btn:is(a, button){background-color: #00FFFF; color: #000000;}
.primary-btn:is(a, button):hover {background-color: #FFFFFF; color: #000000;}
.secondary-btn:is(a, button):any-link:hover:not(#options, #navLinks, .primary-btn, .secondary-btn-sml, .primary-btn-sml) {color: #000000 !important;}
.section_content .stacked .cardItem div[data-testid=flowbite-card] {background-color: #000 !important;}
.lastStep div[data-testid=flowbite-card] {background-color: #00FFFF !important;}
.lastStep div[data-testid=flowbite-card] h3, .lastStep div[data-testid=flowbite-card] p {color: #000;}
.lastStep div[data-testid=flowbite-card] a {background-color: #000; border: 1px solid #000 !important;}
.stacked .lastStep div[data-testid=flowbite-card] a:any-link:hover:not(#options, #navLinks, .primary-btn, .secondary-btn-sml, .primary-btn-sml) {background-color: #FFF; border: 1px solid #FFF !important; color: #000 !important;}
.section_content .cardItem div[data-testid=flowbite-card] {background: rgba(242, 248, 253, 0.10) !important;}
.platform-img {margin-bottom: 40px;}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

footer .grid, footer .divider {
  display: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 639px) {
  .stacked {
      background-color: #000000 !important;
  }
}
